import React from "react"

import Layout from "../components/_shared/Layout"
import SEO from "../components/_shared/SEO"

import GoogleMap from "../components/Contacts/GoogleMap"
import ContactsInfo from "../components/Contacts/ContactsInfo"
import WorkWithUsSection from "../components/Contacts/WorkWithUsSection"

// ----------------------------------------------------------------------------
const ServicesPage = () => (
  <Layout pageTitle="Contatti">
    <SEO title="Contatti" />

    <GoogleMap />

    <ContactsInfo />

    <WorkWithUsSection />
  </Layout>
)

export default ServicesPage
