import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import WorkWithUsForm from "./WorkWithUsForm"

//------------------------------------------------------------------------------

function ContactUsSection(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <section className={props.className}>
      <h6>LAVORA CON NOI</h6>

      <h2>Unisciti al nostro team.</h2>

      <WorkWithUsForm />
    </section>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

ContactUsSection.propTypes = {
  className: PropTypes.string.isRequired,
}

ContactUsSection.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledContactUsSection = styled(ContactUsSection)`
  & {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100vw;
    height: 950px;
    background: ${props => props.theme.isabelline};

    > h6 {
      margin-top: 75px;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      color: ${props => props.theme.yaleBlue};
    }

    > h2 {
      text-align: center;
      color: ${props => props.theme.primaryTextColor};
      margin-bottom: 55px;
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      height: auto;

      > h6 {
        margin-top: 47.1px;
        margin-bottom: 23.9px;
      }

      > h2 {
        margin-bottom: 40.4px;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledContactUsSection
