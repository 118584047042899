import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import GoogleMapReact from "google-map-react"

// ----------------------------------------------------------------------------

const GOOGLE_API_KEY = "AIzaSyD2J_c2Ck_mQkwHGWY23PxFVxGxTU5iFuM"

//------------------------------------------------------------------------------

function GoogleMap(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function renderMarker(map, maps) {
    maps &&
      new maps.Marker({
        position: {
          lat: 45.680906,
          lng: 11.958334,
        },
        map,
        title: "",
      })
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div
      className={props.className}
      style={{ height: "501px", width: "100vw" }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
        defaultCenter={props.center}
        defaultZoom={props.zoom}
        onGoogleApiLoaded={({ map, maps }) => renderMarker(map, maps)}
      />
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

GoogleMap.propTypes = {
  className: PropTypes.string.isRequired,
  center: PropTypes.object,
  zoom: PropTypes.number,
}

GoogleMap.defaultProps = {
  center: {
    lat: 45.680906,
    lng: 11.958334,
  },
  zoom: 18,
}

// ----------------------------------------------------------------------------

const StyledGoogleMap = styled(GoogleMap)`
  & {
    iframe {
      border: none;
      width: 100%;
      height: 100%;
    }
    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      height: 249px !important;
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledGoogleMap
