import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

//------------------------------------------------------------------------------

function ContactsInfo(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={props.className}>
      <h1>
        Massimizza le
        <br /> vendite online con
        <br /> l’efficienza e rapidità
        <br />
        dei nostri servizi.
        <br /> Contattaci!
      </h1>
      <div className="info-container">
        <div className="column first-column">
          <p className="company-name">Salesbridge S.r.l.</p>

          <dl>
            <dt>Indirizzo</dt>
            <dd>Via per Salvatronda, 15</dd>
            <dd>31033 Castelfranco Veneto</dd>
            <dd>Italia</dd>
          </dl>
        </div>

        <div className="column second-column">
          <dl>
            <dt>Mail</dt>
            <dd>
              <a href={"mailto:contact@salesbridge.it"}>
                contact@salesbridge.it
              </a>
            </dd>
          </dl>

          <dl>
            <dt>Telefono</dt>
            <dd>
              <a href={"tel:+3904381896076"}>0438 1896076</a>
            </dd>
          </dl>
          <dl>
            <dt>Cellulare</dt>
            <dd>
              <a href={"tel:+393351816547"}>335 1816547</a>
            </dd>
          </dl>
          <dl>
            <dt>Partita IVA</dt>
            <dd>05013730261</dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

ContactsInfo.propTypes = {
  className: PropTypes.string.isRequired,
}

ContactsInfo.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledContactsInfo = styled(ContactsInfo)`
  & {
    padding-top: 116px;
    padding-bottom: 123px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > h1 {
      color: ${props => props.theme.primaryTextColor};
    }

    .info-container {
      margin-left: 49px;
      display: flex;
      flex-direction: column;

      .column {
        display: flex;
        flex-direction: column;
      }

      .first-column {
        .company-name {
          margin-bottom: 16px;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }

        dl {
          margin: 0;
          font-family: "Poppins ExtraLight";
          font-size: 16px;
          line-height: 22px;

          dd {
            margin: 0;
            font-family: "Poppins";
            font-size: 16px;
            line-height: 22px;
            color: ${props => props.theme.primaryTextColor};
          }
        }
      }

      .second-column {
        flex: 1;

        padding-top: 24px;

        dl {
          margin: 0;
          font-family: "Poppins ExtraLight";
          font-size: 16px;
          line-height: 22px;

          dt {
            display: inline;
          }

          dd {
            display: inline;
            margin: 0 0 0 8px;
            font-family: "Poppins";
            font-size: 16px;
            line-height: 22px;

            a {
              text-decoration: none;
              color: ${props => props.theme.primaryTextColor};
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > h1 {
        text-align: center;
      }

      .info-container {
        margin-left: 0;
      }

      .column {
        text-align: center;
      }

      .first-column {
        margin-top: 45px;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledContactsInfo
